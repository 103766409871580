<template>
  <div class="complete">
    <img class="icon" src="@/assets/images/icon/complete.svg" />
    <h2 class="title">批量导入完成</h2>
    <p class="desc">
      成功导入设备数据<span class="big success"
        >{{ successData.length - importFailData.length }}条</span
      ><span v-if="importFailData.length"
        >，失败<span class="big fail">{{ importFailData.length }}条</span></span
      >
    </p>
    <div class="box-btn">
      <el-button type="primary" @click="handleComplete"> 完成 </el-button>
      <el-button
        type="primary"
        plain
        v-if="importFailData.length"
        @click="handleDownload"
      >
        下载失败明细
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { export_json_to_excel as Export2Excel } from "@/utils/excel/Export2Excel";

export default {
  name: "StepComplete",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      successData: "account/successData",
      importFailData: "account/importFailData",
    }),
  },
  methods: {
    ...mapMutations({
      clearBatchImportData: "account/CLEAR_BATCH_IMPORT_DATA",
    }),
    handleComplete() {
      this.clearBatchImportData();
      this.$emit("complete");
    },
    handleDownload() {
      const excelTHead = ["行数", "设备编号", "错误信息"];
      const excelData = this.importFailData.map((item) => [
        item.lineNumber,
        item.errorRes?.deviceNo,
        item.errorMessage,
      ]);
      const excelName = "失败明细";
      Export2Excel(excelTHead, excelData, excelName);
    },
  },
};
</script>
<style lang="scss" scoped>
.complete {
  margin: 50px auto;
  text-align: center;
  .icon {
    width: 72px;
    height: 72px;
  }
  .title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #333;
  }
  .desc {
    margin-top: 20px;
    color: #999;
    .big {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      &.success {
        color: #16a65d;
      }
      &.fail {
        color: #ffac05;
      }
    }
  }

  .box-btn {
    margin-top: 35px;
    .el-button {
      min-width: 100px;
      + .el-button {
        margin-left: 13px;
      }
    }
  }
}
</style>
