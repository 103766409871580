<template>
  <div class="page">
    <gc-header header-data="batchImport"> </gc-header>
    <div class="container">
      <ul class="step">
        <li
          class="step-item"
          :class="{
            active: stepActive === index,
            pass: stepActive > index,
          }"
          v-for="(item, index) in steps"
          :key="index"
        >
          <div class="num">
            <i v-if="stepActive > index" class="iconfont el-icon-check"></i>
            <span v-else>{{ index + 1 }}</span>
          </div>
          <div class="title">{{ item }}</div>
          <div class="line" v-if="index !== steps.length - 1"></div>
        </li>
      </ul>

      <step-upload
        ref="stepUpload"
        v-show="stepActive === 0"
        @upload="stepActive = 1"
      ></step-upload>
      <step-check
        v-show="stepActive === 1"
        @next="stepActive = 2"
        @reupload="handleReupload"
      ></step-check>
      <step-complete
        v-show="stepActive === 2"
        @complete="handleReupload"
      ></step-complete>
    </div>
  </div>
</template>

<script>
import StepUpload from "./components/StepUpload.vue";
import StepCheck from "./components/StepCheck.vue";
import StepComplete from "./components/StepComplete.vue";
import { mapActions } from "vuex";

export default {
  name: "BatchImport",
  components: { StepUpload, StepCheck, StepComplete },
  data() {
    return {
      steps: ["上传文件", "合规检查", "导入完成"],
      stepActive: 0,
    };
  },
  methods: {
    ...mapActions({
      judgeNeedPickOrg: "user/judgeNeedPickOrg",
    }),
    handleReupload() {
      this.$refs?.stepUpload?.handleDeleteFile();
      this.stepActive = 0;
    },
  },
  activated() {
    this.judgeNeedPickOrg();
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 24px;
}
.step {
  height: 112px;
  @include flex-center;
  &-item {
    @include flex-center;
    &.active {
      .num {
        border: 1px solid $base-color-theme;
        color: #fff;
        background: $base-color-theme;
      }
      .title {
        color: $base-color-theme;
        font-weight: 500;
        color: #333;
      }
    }
    &.pass {
      .num {
        border: 1px solid $base-color-theme;
        color: $base-color-theme;
      }
      .title {
        color: $base-color-theme;
        color: $base-color-theme;
      }
      .line {
        background-color: $base-color-theme;
      }
    }

    .num {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #999;
      color: #999;
      @include flex-center;
    }
    .title {
      margin-left: 8px;
      font-size: 16px;
      color: #999;
    }
    .line {
      margin: 0 16px;
      width: 10vw;
      min-width: 50px;
      height: 1px;
      background-color: #d9d9d9;
    }
  }
}
.icon {
  width: 100px;
  height: 100px;
}
</style>
